import styled from "styled-components/macro";
import { Button } from "../../components/Button";
import { BackButton } from "../../components/Overpage/BackButton";

import NoSubscriptionImg from "./assets/no-subscription.png";
import NoSubscription2XImg from "./assets/no-subscription@2x.png";
import NoSubscription3XImg from "./assets/no-subscription@3x.png";

export function LockedAccount() {
  return (
    <Container>
      <TopPanel>
        <BackButton defaultTo="/talents" />
      </TopPanel>
      <Content>
        <Image
          src={NoSubscriptionImg}
          srcSet={`${NoSubscription2XImg} 2x,${NoSubscription3XImg} 3x`}
        />
        <Title>Your account is temporarily blocked</Title>
        <Subtitle>
          We apologize for any inconvenience, but it seems that your account has been temporarily blocked. This could be due to reaching the maximum limit of cards allowed for your current plan, or there might be an issue with your payment method.
          <br /><br />
          To swiftly resolve this matter and ensure uninterrupted access to our service, we kindly ask you to get in touch with our dedicated support team at{" "}
          <a href="mailto:hi@baselance.co">hi@baselance.co</a>.
          Our team will provide personalized assistance and guide you through the necessary steps to address the issue promptly.
          <br /><br />
          We appreciate your understanding and cooperation. Thank you for choosing Baselance!
        </Subtitle>
        <ButtonContainer>
          <Button
            size="m"
            kind="contained"
            as="a"
            target="_blank"
            href="https://www.baselance.co/pricing"
          >
            Pricing
          </Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  background: #f6f8fa;
  min-height: 100%;
  box-sizing: border-box;
`;

const TopPanel = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
`;

const Image = styled.img`
  margin-top: 24px;
  max-width: 80%;
`;

const Title = styled.h1`
  margin-top: 30px;
  text-align: center;
`;

const Subtitle = styled.p`
  margin-top: 10px;
  max-width: 640px;
`;

const ButtonContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;
